import React, { useState } from 'react';
import PortfolioPage from '../../components/PortfolioPage';
import Thumbnail from '../../images/portfolio/DodensPraktikant/thumbnail.png';
import Characters01 from '../../images/portfolio/DodensPraktikant/characters01.png'
import Characters02 from '../../images/portfolio/DodensPraktikant/characters02.png'
import Characters03 from '../../images/portfolio/DodensPraktikant/characters03.png'
const PortfolioDodensPraktikant = () => {
    return <PortfolioPage img={Thumbnail} url="https://player.vimeo.com/video/926199027" title="Dødens Praktikant" description={<>
        <p>I 1300-tallets Norge, plaget av pest og sykdom, er det å være Døden langt fra enkelt. Det er mye arbeid som må gjøres. Hvordan håndterer Døden sin nye, ivrige praktikant, Konrad?</p>
        <p  className="mt-6">(2024)</p>
        <p className="mt-6">‘Dødens Praktikant’ er The Branch sin første egne kortfilmproduksjon. Ideen ble til da vi jobbet på med et kundeoppdrag der en morsom skisse ga liv til en ny ide. Vi hentet inspirasjon fra en rekke serier og filmer fra vår oppvekst, som med sin blodige, men slapstick-aktige stil, påvirket oss under produksjonen av Dødens Praktikant. Filmen er støttet av Midtnorsk Filmsenter og Fond for lyd og bilde, og hadde premiere på kortfilmfestivalen i Grimstad 2024.</p>
        <div className='mt-12'>
            <p>INFO</p>
            <ul className='list-disc px-8'>
                <li>
                    Lengde: 15 min
                </li>
                <li>
                    Språk: Norsk, svensk / Norwegian, Swedish
                </li>
                <li>
                    Regi: Peter Alan Clayborough, Magne Skagen, Audun Andreas Erikstad Løkås
                </li>
                <li>
                    Manus: Peter Alan Clayborough, Magne Skagen, Audun Andreas Erikstad Løkås
                </li>
                <li>
                    Lyd: Torje Johannessen
                </li>
                <li>
                    Musikk: Eilif Grøn Hensvold
                </li>
                <li>
                    Animasjon: Peter Alan Clayborough, Audun Andreas Erikstad Løkås, Magne Skagen
                </li>
                <li>
                    Med: Mathias Henning, Hanna Pettersen, Oskar Hotvedt Kjærra
                </li>
                <li>
                    Produsent: Audun Andreas Erikstad Løkås
                </li>
                <li>
                    Intern: Dani Pardiñas, Pauline Dts, Abdur Rahat Omar`
                </li>
            </ul>
        </div>
    </>        

    
    }>
         <div className="gap-8 mt-32 lg:px-0 max-w-4xl">
              <img src={Characters02} className="my-4"/>
              <img src={Characters03} className="my-4" />
              <img src={Characters01} className="my-4"/>
          </div>

  

    </PortfolioPage>

}

export default PortfolioDodensPraktikant;